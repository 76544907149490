import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Item({ label, linkTo, image }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      style={{
        display: "block",
        opacity: isLoading ? 0 : 1,
        transition: "opacity .4s"
      }}
    >
      <img
        alt={label}
        onLoad={() => setIsLoading(false)}
        width="50px"
        src={image}
        style={{ verticalAlign: "middle" }}
      />
      <span>{label}</span>
      <Link to={linkTo}>Details</Link>
    </div>
  );
}
