import { useState, useEffect } from "react";
export default function useFilmDatabase() {
  const [films, setFilms] = useState([]);
  const [input, setInput] = useState("");
  const [ids, setIds] = useState([]);

  useEffect(
    () => {
      input &&
        fetch(`https://www.omdbapi.com/?s=${input}&apikey=3a9ad06c`)
          .then(res => res.json())
          .then(movie => setFilms(movie.Search));
    },
    [input]
  );

  useEffect(
    () => {
      setFilms([]) ||
        ids.forEach(id => {
          fetch(`https://www.omdbapi.com/?i=${id}&apikey=3a9ad06c`)
            .then(res => res.json())
            .then(movie => setFilms(films => [...films, movie]));
        });
    },
    [ids]
  );

  return {
    films,
    search: keyword => {
      setInput(keyword);
    },
    getMovie: (...ids) => {
      setIds(ids);
    }
  };
}
