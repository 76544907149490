import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useFilmDatabase from "../hooks/useFilmDatabase";

export default function MovieDetails({ id }) {
  const { films, getMovie } = useFilmDatabase();
  useEffect(
    () => {
      getMovie(id);
    },
    [id]
  );

  const film = films.length && films[0];
  console.log(film);
  return (
    <div>
      <Link to="/">Home</Link>

      {film && (
        <div
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
            //alignItems: "middle"
          }}
        >
          <div style={{ display: "flex" }}>
            <img
              height="300px"
              style={{ verticalAlign: "middle" }}
              src={film.Poster}
            />
            <div style={{ padding: 10 }}>
              <h1>{film.Title}</h1>
              {film.Year}
              <p style={{ opacity: 0.7 }}>{film.Genre}</p>
              <p>{film.Plot}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
