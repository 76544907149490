import React, { useEffect } from "react";
import useFilmDatabase from "../hooks/useFilmDatabase";
import Item from "./Item";

export default function TrendingMovies() {
  const { films, getMovie } = useFilmDatabase();

  useEffect(() => {
    getMovie("tt2674426", "tt1856010", "tt1285016");
  }, []);

  return (
    <div>
      <h1>Trending movies</h1>
      {films.map(film => (
        <Item
          key={film.imdbID}
          linkTo={film.imdbID || "nothing"}
          label={film.Title}
          image={film.Poster}
        />
      ))}
    </div>
  );
}
