import React from "react";
import useFilmDatabase from "../hooks/useFilmDatabase";
import Item from "./Item";

export default function SearchMovies() {
  const { films, search } = useFilmDatabase();
  console.log(films);
  return (
    <div>
      <input
        placeholder="Search here ..."
        onKeyUp={e => e.which === 13 && search(e.target.value)}
      />

      {films.map(film => (
        <Item
          key={film.imdbID}
          label={film.Title + ` (${film.Year})`}
          image={film.Poster}
          linkTo={film.imdbID}
        />
      ))}
    </div>
  );
}
