import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import SearchMovies from "./components/SearchMovies";
import MovieDetails from "./components/MovieDetails";
import TrendingMovies from "./components/TrendingMovies";
import "./styles.css";

export default function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Route path="/" component={SearchMovies} exact />

        <Route
          path="/:id"
          render={({ match }) => <MovieDetails id={match.params.id} />}
        />
        <TrendingMovies />
      </div>
    </BrowserRouter>
  );
}
